import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

class BlogIndex extends React.Component {
  render() {
    console.log(this.props)
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/blog' : '/blog/' + (currentPage - 1).toString()
    const nextPage = '/blog/' + (currentPage + 1).toString()

    return (
      <Layout>
        <Helmet>
            <title>Blog</title>
            <meta name="description" content="NamasteGod Blog Posts" />
        </Helmet>
        <div id="main" className="blog">
            <section id="one">
                <div className="inner">
                  <header>
                    <h1>Blog</h1>
                  </header>
                  {posts.map(({ node }) => {
                    const title = node.frontmatter.title || node.frontmatter.slug
                    return (
                      <div className="posts" key={node.frontmatter.slug}>
                        <h3>
                          <Link to={node.frontmatter.slug}>
                            {title}
                          </Link>
                        </h3>
                        <span className="date">
                          <i class="fa fa-calendar" aria-hidden="true"></i>
                          <span>{node.frontmatter.date}</span>
                        </span>
                        <span className="type">
                          <i class="fa fa-folder" aria-hidden="true"></i>
                          <span>{node.frontmatter.puja_type}</span>
                        </span>
                        <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                      </div>
                    )
                  })}
                  {!isFirst && (
                    <Link className="pre" to={prevPage} rel="prev">
                      ← Previous
                    </Link>
                  )}
                  {!isLast && (
                    <Link className="nxt" to={nextPage} rel="next">
                      Next →
                    </Link>
                  )}
              </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {fileAbsolutePath: {regex: "/posts/"}}
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            date(formatString: "DD MMM, YYYY")
            title
            slug
            puja_type
            photo {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
